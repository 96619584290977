import logo from '../assets/images/logo.png';
import apontando from '../assets/images/apontando1.png';
import refresh from '../assets/images/refresh.png';
import { useForm, SubmitHandler } from "react-hook-form"
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router';
// import { Container } from './styles';

function ConsultarBTag() {

    const [searching, setSearch] = useState(false);
    const [data, setData] = useState({});
    const [savebTag, setBTag] = useState("");
    const [update, setUpdate] = useState(false);

    const { id } = useParams();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    useEffect(async () => {
        if(id) {
            if (id.includes("@")) {
                const i = await fetch(`https://api.betao.com/affiliates/userInfo`, {
                    method: 'POST',
                    body: JSON.stringify({
                        data: {
                            email: id,
                        }
                    })
                })
    
                const iJSON = await i.json();

                if(iJSON && iJSON.users) {
                    setData(iJSON);
                    setSearch(true);
                    setBTag(id);
                }

            } else {
                const i = await fetch(`https://api.betao.com/affiliates/userInfo`, {
                    method: 'POST',
                    body: JSON.stringify({
                        data: {
                            btag: id,
                        }
                    })
                })
    
                const iJSON = await i.json();
    
                if(iJSON && iJSON.users) {
                    setData(iJSON);
                    setSearch(true);
                    setBTag(id);
                }
            } 
        }
    }, [id])

    const onSubmit = async (data) => {
        setBTag(data.btag);
        let res = {};
        if (data.btag.includes("@")) {
            const i = await fetch(`https://api.betao.com/affiliates/userInfo`, {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        email: data.btag,
                    }
                })
            })

            const iJSON = await i.json();

            if(iJSON && iJSON.users) {
                setData(iJSON);
                setSearch(true);
                // setBTag(id);
            }

        } else {
            const i = await fetch(`https://api.betao.com/affiliates/userInfo`, {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        btag: data.btag,
                    }
                })
            })

            const iJSON = await i.json();

            if(iJSON && iJSON.users) {
                setData(iJSON);
                setSearch(true);
                // setBTag(id);
            }
        }
        window.history.replaceState(null, "Consultat BTAG", '/consultar-btag/' + data.btag);
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
                <div className="container">
                    <a className="navbar-brand" href="/"><img src={logo} alt="logo" height="20" /></a>
                    <button className="navbar-toggler pull-left float-left" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="material-icons-round">
                            dehaze
                        </i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/indique-ganhe">Indique e Ganhe</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/socio-betao">Sócio Betão</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href="/consultar-btag">Consultar BTAG</a>
                            </li>
                        </ul>

                    </div>
                    <a href="https://socio.betao.com/login" className="btn-login" style={{ color: "#fff" }}>
                        Login
                    </a>
                    <a href="/#compare" className="btn-main" style={{ color: "#fff" }}>
                        Faça seu cadastro
                    </a>

                </div>
            </nav>
            <section style={{ marginTop: 60 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 section-head text-center">
                            <div className="section-head-tag" style={{ fontSize: 25, marginTop: 60 }}>Informações BTAG</div>
                            <h2 className="section-head-title" style={{ fontSize: 55 }}>Consultar BTAG</h2>
                            {/* <p>Quer a <strong>experiência de sócio</strong>, ganhar <strong>comissões rápidas </strong>ou garantir <strong>valores maiores?</strong> A gente tem um plano para você!</p> */}
                        </div>
                    </div>

                    {!searching && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: -50 }}>
                        <form onSubmit={handleSubmit(onSubmit)} className='field'>
                            <input {...register("btag", { required: true })} placeholder='Insira E-mail/BTAG' />
                            {errors.exampleRequired && <span>Campo obrigatório</span>}

                            <button type="submit">Enviar</button>
                        </form>
                    </div>}

                    {searching &&
                        <div style={{ background: "#1a2c2e", padding: 30, marginTop: -50, borderRadius: 20 }}>
                            <div onClick={() => {
                                setSearch(false);
                                setData({});
                                window.history.replaceState(null, "Consultat BTAG", '/consultar-btag/');
                            }} className='backButton'>Voltar</div>
                            <div style={{ color: '#fff', marginTop: -10 }}>Nome:</div> <div>{data.first_name}</div>
                            <div style={{ color: '#fff', marginTop: 10 }}>Criado em:</div> <div>{moment(data.created_at).format("DD/MM/YYYY")}</div>
                            {/* <div style={{ color: '#fff', marginTop: 10 }}>Plano:</div> <div>{data.plan_name}</div> */}
                            <div style={{ marginTop: 20, color: '#FF9400', fontSize: 15, fontStyle: 'italic' }}>Os dados são atualizados a cada 5 minutos.</div>
                            <div style={{ color: '#FFF', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>Cadastros: <div style={{ color: "rgb(255, 148, 0)", marginLeft: 5 }}>{data.users.length}</div></div>
                                    <img style={{ marginLeft: 10, height: 16, cursor: 'pointer', animation: update ? 'spin 4s linear infinite' : '', }} onClick={async () => {
                                        setUpdate(true);
                                        setTimeout(() => {
                                            setUpdate(false);
                                        }, 2000);
                                        if (savebTag.includes("@")) {
                                            const i = await fetch(`https://api.betao.com/affiliates/userInfo`, {
                                                method: 'POST',
                                                body: JSON.stringify({
                                                    data: {
                                                        email: savebTag
                                                    }
                                                })
                                            })
                                
                                            const iJSON = await i.json();
                                            setData(iJSON);
                                            setSearch(true);
                                
                                        } else {
                                            const i = await fetch(`https://api.betao.com/affiliates/userInfo`, {
                                                method: 'POST',
                                                body: JSON.stringify({
                                                    data: {
                                                        btag: savebTag
                                                    }
                                                })
                                            })
                                
                                            const iJSON = await i.json();
                                
                                            setData(iJSON);
                                            setSearch(true);
                                        }
                                    }} src={refresh} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>✅ Depósitos: <div style={{ color: "rgb(255, 148, 0)", marginLeft: 5 }}>{data.users.filter((i) => i.first_deposit === true).length}</div></div>
                            </div>

                            <div style={{ marginTop: 10 }}>
                                {data.users.map((i, index) => {
                                    return (
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ }}>
                                                {i.name}
                                            </div>
                                            <div>
                                                {i.first_deposit ? "✅" : "❌"}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </section>
        </div>
    );
}

export default ConsultarBTag;