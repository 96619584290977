import logo from '../assets/images/logo.png';
import passo_a_passo from '../assets/images/passo-a-passo.png';
// import { Container } from './styles';

function IndiqueGanhe() {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
                <div className="container">
                    <a className="navbar-brand" href="/"><img src={logo} alt="logo" height="20" /></a>
                    <button className="navbar-toggler pull-left float-left" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="material-icons-round">
                            dehaze
                        </i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link active" href="/indique-ganhe">Indique e Ganhe</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/socio-betao">Sócio Betão</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="/consultar-btag">Consultar BTAG</a>
                            </li> */}
                        </ul>

                    </div>
                    <a href="https://socio.betao.com/login" className="btn-login" style={{ color: "#fff" }}>
                        Login
                    </a>
                    <a href="/#compare" className="btn-main" style={{ color: '#fff' }}>
                        Faça seu cadastro
                    </a>
                </div>
            </nav>

            <section id="compare" style={{ marginTop: 60 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 section-head text-center">
                            <div className="section-head-tag" style={{ fontSize: 25 }}>COMO FUNCIONA</div>
                            <h2 className="section-head-title" style={{ fontSize: 55 }}>O INDIQUE E GANHE?</h2>
                            <p>Quer a <strong>experiência de sócio</strong>, ganhar <strong>comissões rápidas </strong>ou garantir <strong>valores maiores?</strong> A gente tem um plano para você!</p>
                        </div>
                    </div>
                    <img src={passo_a_passo} className="img-fluid" style={{ marginLeft: 20, marginBottom: -60 }}/>
                    <div className="row">
                        <div className="col-12">
                            <div className="product-table table-responsive">
                                <section id="customer-reviews">
                                    <div className="container">

                                        <div className="row reviews-container">

                                            <div className="indiqueGanheContainer">
                                                <h2 className="section-head-title">Indique e ganhe - Bronze</h2>
                                                <p><span style={{ fontWeight: 400 }}>No Sócio Betão Bronze sempre que um indicado for efetivado você ganha R$5 de comissão. </span><span style={{ fontWeight: 400 }}><br/></span><span style={{ fontWeight: 400 }}>Mas isso não é tudo. Você tem 10% de participação nos resultados da casa nas apostas dos seus indicados. <br/></span></p><p><span style={{ fontWeight: 400 }}>Isso quer dizer que quando seus afiliados apostarem na plataforma, 10% de tudo que a casa ganhar vai para você e quando a casa perder, 10% dos ganhos do seu afiliado será deduzido da sua comissão.&nbsp;</span></p><p><strong>Efetivação:</strong> Indicado somar R$10 em depósitos em nossa Plataforma.</p><p><strong>CPA:</strong> R$5</p><p><b>Participação nos Resultados: </b>10%</p>
                                                <a href="https://socio.betao.com/register?plan=INDIQUE-BRONZE" className="btn-main" style={{ color: "#fff", maxWidth: 220 }}>Quero este plano!</a>
                                            </div>

                                            <div className="indiqueGanheContainer">
                                                <h2 className="section-head-title">Indique e ganhe - Prata</h2>
                                                <p>No Sócio Betão Prata sempre que um indicado for efetivado você ganha R$10 de comissão.<br/>Mas isso não é tudo. Você tem 10% de participação nos resultados da casa nas apostas dos seus indicados.</p><p>Isso quer dizer que quando seus afiliados apostarem na plataforma, 10% de tudo que a casa ganhar vai para você e quando a casa perder, 10% dos ganhos do seu afiliado será deduzido da sua comissão.&nbsp;</p><p><strong>Efetivação:</strong> Indicado somar R$20 em depositos na nossa Plataforma.</p><p><strong>CPA:</strong> R$10</p><p><b>Participação nos Resultados:&nbsp;</b>10%</p>
                                                <a href="https://socio.betao.com/register?plan=INDIQUE-PRATA" className="btn-main" style={{ color: "#fff", maxWidth: 220 }}>Quero este plano!</a>
                                            </div>

                                            <div className="indiqueGanheContainer">
                                                <h2 className="section-head-title">Indique e ganhe - Ouro</h2>
                                                <p>No Sócio Betão Ouro sempre que um indicado for efetivado você ganha R$15 de comissão.<br/>Mas isso não é tudo. Você tem 10% de participação nos resultados da casa nas apostas dos seus indicados.</p><p>Isso quer dizer que quando seus afiliados apostarem na plataforma, 10% de tudo que a casa ganhar vai para você e quando a casa perder, 10% dos ganhos do seu afiliado será deduzido da sua comissão.&nbsp;</p><p><strong>Efetivação:</strong> Indicado somar R$30 em depósitos na nossa na Plataforma.</p><p><strong>CPA:</strong> R$15</p><p><b>Participação nos Resultados:&nbsp;</b>10%</p>
                                                <a href="https://socio.betao.com/register?plan=INDIQUE-OURO" className="btn-main" style={{ color: "#fff", maxWidth: 220 }}>Quero este plano!</a>
                                            </div>


                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <footer id="footer">
                <div className="container">
                    <div className="row footer-main">
                        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 text-center">
                            <a className="footer-brand" href="#"><img src={logo} alt="logo" height="20" /></a>
                            <div className="footer-social-links mt-4">
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                        <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                                    </svg>
                                </a>
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                    </svg>
                                </a>
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                        <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                    </svg>
                                </a>
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                        <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6">

                        </div>
                    </div>
                    <div className="row footer-rights">
                        <div className="col-12 text-center">
                            Copyright © 2020 <a href="#">Betão</a>. <a>Todos os direitos reservados.</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default IndiqueGanhe;