import logo from '../assets/images/logo.png';
import betao_charac from '../assets/images/betao_charac.png';
import passo_a_passo from '../assets/images/passo-a-passo.png';

function Home() {
  return(
    <div>
         <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
        <div class="container">
            <a class="navbar-brand" href="/"><img src={logo} alt="logo" height="20" /></a>
            <button class="navbar-toggler pull-left float-left" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <i class="material-icons-round">
                    dehaze
                </i>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link" href="/indique-ganhe">Indique e Ganhe</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/socio-betao">Sócio Betão</a>
                    </li>
                    {/* <li class="nav-item">
                        <a class="nav-link" href="/consultar-btag">Consultar BTAG</a>
                    </li> */}
                </ul>
                
            </div>
            <a href="https://socio.betao.com/login" className="btn-login" style={{ color: "#fff" }}>
                Login
            </a>
            <a href="/#compare" class="btn-main" style={{ color: "#fff" }}>
                Faça seu cadastro
            </a>
        </div>
    </nav> 
    
    
    <section id="cover">
        <div class="container">
            <div class="row">
                <div class="col-md-6 cover-image align-self-center">
                    <img src={betao_charac} class="img-fluid" alt="product" />
                </div>
                <div class="col-md-6 align-self-center cover-body">
                    <h1>Seja um Sócio Betão</h1>
                    <p>
                        Ajude o Betão a chegar mais longe e ganhe dinheiro com isso. Escolha o plano que combina mais com você e se torne um Sócio Betão.
                    </p>
                    <a href="#compare" class="btn-main" style={{ color: '#fff' }}>Entre para o time!</a>
                </div>
                <div class="shapes-container">
                    <div class="bg-shape"></div>
                 </div>
            </div>
        </div>
    </section>
    
    <section id="compare">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 section-head text-center">
                    <div class="section-head-tag" style={{ fontSize: 25 }}>COMO FUNCIONA</div>
                    <h2 class="section-head-title" style={{ fontSize: 55 }}>NA PRÁTICA</h2>
                    <p>Quer a <strong>experiência de sócio</strong>, ganhar <strong>comissões rápidas </strong>ou garantir <strong>valores maiores?</strong> A gente tem um plano para você!</p>
                </div>
            </div>
            <img src={passo_a_passo} class="img-fluid" style={{ marginBottom: 20, marginLeft: 20, marginBottom: -60 }} />
            <div class="row">
                <div class="col-12">
                    <div class="product-table table-responsive">
                        <section id="customer-reviews">
                            <div class="container">
                                
                                <div class="row reviews-container">

                                    <div class="indiqueGanheContainer">
                                        <h2 class="section-head-title">Indique e Ganhe</h2>
                                        <p class="section-head-body">Sempre que alguém que se cadastrou pelo seu link fizer a sua efetivação na plataforma você ganha a sua comissão. Simples assim.</p>
                                        <a href="/indique-ganhe" class="btn-main" style={{ color: "#fff", maxWidth: '270px' }}>Saiba mais sobre o plano!</a>
                                    </div>

                                    <div class="indiqueGanheContainer">
                                        <h2 class="section-head-title">Sócio Betão</h2>
                                        <p class="section-head-body"><p>Mais do que ganhar uma comissão pelos indicados <strong>você quer ter a experiência de sócio e participar dos resultados do Betão?</strong></p><p>No Sócio Betão sempre que um indicado se efetivar na plataforma você ganha sua comissão, mas você também tem <strong>participação nos resultados das apostas deles.</strong>&nbsp;</p></p>
                                        <a href="/socio-betao" class="btn-main" style={{ color: "#fff", maxWidth: '270px' }}>Saiba mais sobre o plano!</a>
                                    </div>

                                    {/* <div class="indiqueGanheContainer">
                                        <h2 class="section-head-title">Sócio Influencer</h2>
                                        <p class="section-head-body">Já tem uma comunidade de seguidores? Temos um plano especial feito só pra você. No Sócio Influencer você ganha uma comissão sempre que um indicado faz seu depósito na plataforma e ainda garante uma participação nos lucros da casa. </p>
                                        <a href="#" class="btn-main" style={{ color: "#fff", maxWidth: '270px' }}>Saiba mais sobre o plano!</a>
                                    </div> */}
                    
                    
                                </div>
                            </div>
                        </section>         
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="faqs">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 section-head text-center">
                    <div class="section-head-tag">FAQs</div>
                    <h2 class="section-head-title">Perguntas Frequentes</h2>
                    <p class="section-head-body">Você pode encontrar a resposta para suas dúvidas aqui.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-12 faqs-container offset-lg-2">
                    <div class="faq">
                        <div class="faq-head">
                            <h5 class="mb-0" data-toggle="collapse" data-target="#faq1"  aria-expanded="true">
                                Como funciona o sistema de remuneração por indicação na plataforma Betão?
                            </h5>
                        </div>                      
                        <div class="collapse show" id="faq1">
                            <div class="faq-body">
                                <p>Na plataforma Betão, você pode ganhar dinheiro indicando amigos e familiares para se cadastrarem e participarem dos planos disponíveis. Os ganhos por indicação variam de acordo com o plano escolhido pelo indicado, e você pode encontrar mais detalhes sobre os planos e suas respectivas remunerações na seção dedicada aos planos dentro de nosso site.</p>
                            </div>
                        </div>
                    </div>
                    <div class="faq">
                        <div class="faq-head">
                            <h5 class="mb-0" data-toggle="collapse" data-target="#faq2">
                                Quando posso sacar os ganhos provenientes das minhas indicações na plataforma Betão?
                            </h5>
                        </div>                      
                        <div class="collapse" id="faq2">
                            <div class="faq-body">
                                <p>Os ganhos por indicação na plataforma Sócio Betão podem ser sacados assim que atingirem o valor mínimo estabelecido para saque, que é de R$100,00. Assim que você alcançar esse valor mínimo, poderá solicitar o saque e receber os seus ganhos de forma rápida e segura.</p>
                            </div>
                        </div>
                    </div>
                    <div class="faq">
                        <div class="faq-head">
                            <h5 class="mb-0" data-toggle="collapse" data-target="#faq3">
                                Quais são os requisitos para se tornar um sócio influencer na plataforma Betão?
                            </h5>
                        </div>                      
                        <div class="collapse" id="faq3">
                            <div class="faq-body">
                                <p>Para se tornar um sócio influencer na plataforma Sócio Betão, é necessário atender a certos requisitos específicos. Você deve ter uma quantidade de pelo menos 100k de seguidores nas redes sociais, um número mínimo de indicações ativas já em um de nossos planos normais, além de manter um engajamento constante com a plataforma e seus usuários. Além disso, para se tornar sócio influencer, é necessário possuir um bom engajamento em suas redes sociais. Se você se enquadra, se direcione ao nosso chat, que nossa equipe irá avaliar o seu perfil.</p>
                            </div>
                        </div>
                    </div>   
                    <div class="faq">
                        <div class="faq-head">
                            <h5 class="mb-0" data-toggle="collapse" data-target="#faq4">
                                O que devo fazer se o meu indicado não aparecer como minha referência na plataforma Sócio Betão?
                            </h5>
                        </div>                      
                        <div class="collapse" id="faq4">
                            <div class="faq-body">
                                <p>Se o seu indicado não estiver aparecendo como sua referência na plataforma Betão, pode haver algumas razões para isso. É possível que o seu indicado não tenha se cadastrado através do seu link de indicação, ou que já possuísse um cadastro prévio na plataforma antes de utilizar o seu link. Recomendamos que verifique com o seu indicado se ele seguiu corretamente o processo de cadastro através do seu link de indicação para garantir que você receba os devidos créditos pela indicação.</p>
                            </div>
                        </div>
                    </div> 
                    
                    <div class="faq">
                        <div class="faq-head">
                            <h5 class="mb-0" data-toggle="collapse" data-target="#faq5">
                                Como posso garantir que receberei os créditos pela indicação na plataforma Sócio Betão?
                            </h5>
                        </div>                      
                        <div class="collapse" id="faq4">
                            <div class="faq-body">
                                <p>Para garantir que você receba os créditos pela indicação na plataforma Betão, é importante que o seu indicado siga corretamente o processo de cadastro utilizando o seu link de indicação. Certifique-se de compartilhar o seu link de forma clara e direta com os seus amigos e familiares, e esteja disponível para ajudá-los em caso de dúvidas durante o processo de cadastro. Dessa forma, você poderá garantir que receberá os créditos pela sua indicação de forma justa e transparente.</p>
                            </div>
                        </div>
                    </div> 

                    <div class="faq">
                        <div class="faq-head">
                            <h5 class="mb-0" data-toggle="collapse" data-target="#faq6">
                                Existe algum limite de saque na plataforma Sócio Betão?
                            </h5>
                        </div>                      
                        <div class="collapse" id="faq4">
                            <div class="faq-body">
                                <p>Não, na plataforma Betão não há limite de saque. Você pode sacar seus ganhos a qualquer momento e na quantia que desejar, desde que o valor mínimo para saque seja alcançado. Isso proporciona flexibilidade e liberdade para os usuários gerenciarem seus ganhos conforme sua conveniência.</p>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </section>

    
    <footer id="footer">
            <div class="container">
                <div class="row footer-main">
                    <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 text-center">
                        <a class="footer-brand" href="#"><img src={logo} alt="logo" height="20" /></a>
                        <div class="footer-social-links mt-4">
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/>
                                </svg>
                            </a>
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                                </svg>
                            </a>
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/>
                                </svg>
                            </a>
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        
                    </div>
                </div>
                <div class="row footer-rights">
                   <div class="col-12 text-center">
                        Copyright © 2020 <a href="#">Betão</a>. <a>Todos os direitos reservados.</a>
                   </div>
                </div>
            </div>
        </footer>
    </div>
  );
}

export default Home;