import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import IndiqueGanhe from './pages/IndiqueGanhe';
import SocioBetao from './pages/SocioBetao';
import ConsultarBTag from './pages/ConsultarBTag';

function App() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path='/indique-ganhe' element={<IndiqueGanhe />} />
      <Route path='/socio-betao' element={<SocioBetao />} />
      <Route path='/consultar-btag' element={<ConsultarBTag />} />
      <Route path='/consultar-btag/:id' element={<ConsultarBTag />} />
    </Routes>
  );
}

export default App;
